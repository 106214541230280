import React from 'react';
import { graphql } from 'gatsby';
import HeroHeader from '../components/heroheader';
import Layout, { Content } from '../components/Layout';
import {
  Content as ContentV2,
  Layout as LayoutV2,
} from '../components/LayoutV2';
import PageContent from '../components/Page';

export const query = graphql`
  query IndexPageQuery {
    strapiPages(Slug: { eq: "/" }) {
      Title
      Display_Title
      Headline
      Slug
      Key_Visual {
        publicURL
      }
      CTA {
        Text
        URL
        Button
      }
      Content {
        id
        Title
        Subtitle
        Content
        ButtonText
        CTA_Text
        CTA_URL
        Colour
        IconLayout
        ImageLayout
        Introduction
        YouTube_URL
        URL
        Decoration
        ContentLayout
        ColorVariant
        Restriction
        TitleContent
        TitleNote
        SpotlightTitle
        Description
        Layout
        ReviewsTitle
        ReviewsSubtitle
        Box_Item {
          Box_Title
          Box_Link
          BoxImage {
            publicURL
          }
        }
        Redirect_Title
        Redirect_Content
        Redirect_Item {
          Redirect_Title
          Redirect_Content
          Redirect_Link
          RedirectImage {
            publicURL
          }
        }
        Banner_URL
        Banner_Image {
          publicURL
        }
        Button {
          Text
          URL
        }
        Buttons {
          Text
          URL
        }
        Icon {
          publicURL
        }
        ContentDisplayImage {
          publicURL
        }
        SideBySideDisplay
        Travel_With_Confidence_Title
        BackgroundType
        Brush {
          Stroke_Type
        }
        Key_Visual {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
        Galleries {
          Title
          id
        }
        JobRoles {
          Title
        }
        Questions {
          Answer
          Question
          id
        }
        Steps {
          Title
          Subtitle
          Content
          id
        }
        Locations {
          Camp
          Location
          Key_Visual {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
          Button {
            Text
            URL
          }
        }
        Icons {
          Content
          Key_Visual {
            publicURL
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
        }
        Quote_Tabs {
          Tab_Title
          Quotes {
            id
            Content
            Person
            Experience
          }
        }
        Blogs {
          id
          Title
          Intro
          Content
          Category
          Key_Visual {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
        }
        Quotes {
          id
          Content
          Person
          Experience
        }
        TextQuotes {
          id
          Content
          Person
          Experience
        }
        Grid_Items {
          Question
          Answer
        }
        Item1 {
          Content
          Title
          Subtitle
          Key_Visual {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
          Button {
            id
            Text
            URL
          }
        }
        Item2 {
          Content
          Title
          Subtitle
          Key_Visual {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
          Button {
            id
            Text
            URL
          }
        }
        Product_One {
          Title
          Subtitle
          Introduction
          Details
          First_Payment {
            Product_Title
            Product {
              id
            }
          }
          Desposit {
            Product_Title
            Product {
              id
            }
          }
          Second_Payment {
            Product_Title
            Product {
              id
            }
          }
          Final_Text
          Include_Total
          Button {
            Text
            URL
          }
        }
        Product_Two {
          Title
          Subtitle
          Introduction
          Details
          First_Payment {
            Product_Title
            Product {
              id
            }
          }
          Desposit {
            Product_Title
            Product {
              id
            }
          }
          Second_Payment {
            Product_Title
            Product {
              id
            }
          }
          Final_Text
          Include_Total
          Button {
            Text
            URL
          }
        }
        Items {
          Content
          Title
          Subtitle
          Key_Visual {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
          Button {
            Text
            URL
          }
        }
      }
    }
    allStrapiBlogCategories {
      nodes {
        strapiId
        Title
        Content
        Key_Visual {
          publicURL
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
      }
    }
  }
`;
const IndexPage = ({ data }) => {
  const page = data.strapiPages;
  const blogCategories = data.allStrapiBlogCategories.nodes;
  const keyVisualUrl = page.Key_Visual ? page.Key_Visual.publicURL : '';
  const metadata = page.Metadata || { Title: false };

  return (
    <LayoutV2
      className="layout-v2"
      title={page.Title}
      desc={metadata.Description || ''}
      noIndex={metadata.noIndex}
    >
      <div className="relative mb-16">
        <div className="absolute z-10 inset-0 w-full flex flex-col justify-center align-middle text-center">
          <h1 className="font-worksans font-bold text-5xl md:text-6xl text-white">
            Work and travel <br /> abroad{' '}
            <span className="banner-highlight font-fave text-8xl md:text-9xl font-medium text-blue">
              experts
            </span>
          </h1>
          <p className="mt-6 text-base md:text-lg text-white font-semibold">
            Travel like you mean it. Working holidays <br /> and internships
            worldwide.
          </p>
        </div>
        {keyVisualUrl && (
          <HeroHeader
            cta={page.CTA}
            title={page.Headline || page.Display_Title}
            backgroundUrl={keyVisualUrl}
            showAdventureFilter
            fullWidth
          />
        )}
      </div>
      <ContentV2 fullWidth>
        {page.Content &&
          page.Content.map((content) => (
            <div key={content.id}>
              <PageContent
                content={content}
                layoutVersion="V2"
                categories={blogCategories}
              />
            </div>
          ))}
      </ContentV2>
    </LayoutV2>
  );
};

export default IndexPage;
